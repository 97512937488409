<template>
  <LayoutFilter :onFilter="ok"  :onReset="resetForm">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from" @submit.native.prevent>

      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="广告名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="广告分类：" prop="category_id">
          <el-select v-model="form.category_id" placeholder="请选择">
            <el-option v-for="item in categoryIdArr" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="广告类型：" prop="banner_type">
          <el-select v-model="form.banner_type" placeholder="请选择">
            <el-option v-for="item in bannereTypeArr" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="投放范围：" prop="scope_type">
          <el-select v-model="form.scope_type" placeholder="请选择">
            <el-option v-for="item in scopeTypeArr" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="推广时段：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="创建时间：" start-prop="create_start_time" :start-time.sync="form.create_start_time"
          end-prop="create_end_time" :end-time.sync="form.create_end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import {categoryOptions } from '../api/banner-category'
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    positions: {
      type: Array,
    },
    jumptypes: {
      type: Array,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      categoryIdArr:[],
      bannereTypeArr:[
        {id:-1,name:'全部'},
        {id:0,name:'跳转链接'},
        {id:1,name:'首页弹窗'},
        {id:2,name:'图文'},
        {id:3,name:'视频'},
      ],
      scopeTypeArr:[
        {id:0,name:'所有人'},
        {id:1,name:'用户类型'},
        {id:2,name:'用户性别'},
        {id:3,name:'用户职务'},
      ]
    };
  },
  methods: {
    getCategoryOptions(){
      categoryOptions().then(res=>{
        this.categoryIdArr  = res.data
      })
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
    this.getCategoryOptions()
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    jump_type: {
      get() {
        return this.form.jump_type == -2 ? "" : this.form.jump_type;
      },
      set(val) {
        this.form.jump_type = val;
      },
    },
    position: {
      get() {
        return this.form.position == -2 ? "" : this.form.position;
      },
      set(val) {
        this.form.position = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
